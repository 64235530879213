import * as React from 'react'
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";
import Seo from "../../components/Seo"

const AboutPage = () => {
    return (
        <Layout pageTitle="About me">
            <Seo title="About Nicolas Singer" />
            <StaticImage className="ml-2 md:ml-24" imgClassName="rounded-full" src="../../images/moi2.jpg"
                         alt="Nicolas Singer" width={200} height={200}/>
            <div className="flex flex-col md:flex-row">
                <div className="basis-1/2 ml-2 mr-2 md:ml-24 max-w-lg">
                    <div className="text-3xl mt-8 font-bold">Nicolas Singer</div>
                    <div className="mt-4">Enseignant-chercheur à l'école d'ingénieur ISIS - INU Champollion</div>
                    <div className="text-black/50 mt-2">France - Castres</div>
                    <div className="mt-4 items-center">
                        <div>
                            <a href="mailto:nicolas.singer@gmail.com" className="no-underline text-black"><StaticImage src="email_black_24dp.svg" alt="email icone"/>nicolas.singer@gmail.com</a>
                        </div>
                        <div className="mt-2">
                            <a href="https://www.linkedin.com/in/nicolas-singer-9045268a/" className="no-underline visited:text-black"><StaticImage width={20} height={20} src="LI-In-Bug.png" alt="linkedin icone"/> https://www.linkedin.com/in/nicolas-singer</a>
                        </div>
                    </div>
                    <div className="mt-12">
                        <p>
                        <span className="font-bold">Maître de conférences</span> dans une école d'ingénieur spécialisée dans la <span className="font-bold">santé numérique</span>, je suis
                            depuis toujours passionné par la <span className="font-bold">conception logicielle</span>.</p>

                        <p>
                        J'aime l'aspect à la fois artisanal et industriel
                        de ce processus qui consiste à analyser une problématique métier et à y répondre par la création d'une application
                        sur mesure qui doit être au service des utilisateurs et pouvoir évoluer dans le temps.</p>

                        <p>Je m'intéresse à l'ensemble de la chaine qui va de l'analyse des besoins à la mise en production.</p>

                        <p>Ces dernières années, le <span className="italic">DevOps</span>, l'<span className="italic">IoT</span>, le <span className="italic">cloud</span>, nous ont offert des possibilités extraordinaires pour concevoir et déployer des outils au plus prêt des besoins.</p>

                        <p>Les métiers de l'informatique n'ont jamais été aussi riches et exaltants.</p>

                        <p>Former à ces métiers est une chance.</p>

                        <p className="italic">Ce blog parle de tout ça.</p>
                    </div>
                </div>
                <div className="basis-1/2 max-w-xl mr-12">
                    <StaticImage className="mx-auto object-contain" imgClassName="" src="./wordcloud.webp"
                                 alt="Word Cloud"/>
                </div>
            </div>
        </Layout>
    )
}

export default AboutPage
